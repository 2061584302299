/* INVESTOR FACE ICONS */
import WarrenBuffet from "assets/images/investors/warren-buffet.png";
import JackBogle from "assets/images/investors/jack-bogle.png";
import Benjamin from "assets/images/investors/benjamin.png";

import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";
import PeterLynchFull from "assets/images/investors/peter-lynch-full.png";
import JackBogleFull from "assets/images/investors/jack-bogle-full.png";

/* ETF ICONS */
import ETF01 from "assets/images/etf-icons/etf-01.png";
import ETF02 from "assets/images/etf-icons/etf-02.png";
import ETF03 from "assets/images/etf-icons/etf-03.png";
import ETF04 from "assets/images/etf-icons/etf-04.png";
import ETF05 from "assets/images/etf-icons/etf-05.png";
import ETF06 from "assets/images/etf-icons/etf-06.png";
import ETF07 from "assets/images/etf-icons/etf-07.png";
import ETF08 from "assets/images/etf-icons/etf-08.png";

import ETF01Detail from "assets/images/etf-icons/etf-01-detail.png";
import ETF02Detail from "assets/images/etf-icons/etf-02-detail.png";
import ETF03Detail from "assets/images/etf-icons/etf-03-detail.png";
import ETF04Detail from "assets/images/etf-icons/etf-04-detail.png";
import ETF05Detail from "assets/images/etf-icons/etf-05-detail.png";
import ETF06Detail from "assets/images/etf-icons/etf-06-detail.png";
import ETF07Detail from "assets/images/etf-icons/etf-07-detail.png";
import ETF08Detail from "assets/images/etf-icons/etf-08-detail.png";

/* BROKER */
import Alpaca from "assets/images/logos/alpaca.png";

const stocksList = [
  {
    id: 1,
    image: WarrenBuffet,
    name: "Warren Buffet",
    strategyName: "90 / 10 Strategy",
    riskScore: 3.24,
    returnPercentage: 11.77,
    type: "investor",
    underlying: "_BUFFETT",
    about:
      "It has the opportunity to grow quickly, but it can drop just as fast. To balance this risk, bonds that are guaranteed by the U.S. government are added.",
    investorDetails: {
      image: WarrenBuffetFull,
      heading: "Who is Warren Buffet",
      list: [
        "Averaged 20% per year for over 50 years, making him one of the best investors in history",
        "Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: [
        {
          name: "VOO",
          description:
            "Invests in the 500 largest companies in the U.S. This is one of the most popular and best-performing investments in the world.",
          percentage: 90,
        },
        {
          name: "BILS",
          description:
            "Invests in bonds that are guaranteed by the U.S. government. It is extremely safe, so the return is lower.",
          percentage: 10,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who are not afraid of moderate to higher risk.",
        },
        {
          description:
            "Potential for higher returns, because it invests primarily in stocks instead of bonds.",
        },
        {
          description:
            "This strategy uses bonds as a way to balance out some risk.",
        },
        {
          description:
            "Because it invests in the largest 500 companies in the U.S., most money is invested in tech companies like Nvdia, Apple, and Meta.",
        },
      ],
      drawbacks: [
        {
          description:
            "It can sometimes be too volatile for people who do not like risk.",
        },
        {
          description:
            "If the market were to face an extreme decline, it could take longer for this strategy to recover.",
        },
      ],
    },
  },
  // {
  //   id: 2,
  //   image: RayDalio,
  //   name: "Ray Dalio",
  //   strategyName: "All Weather Strategy",
  //   riskScore: 3.6,
  //   returnPercentage: 9.7,
  //   underlying: "_RAY",
  //   about:
  //     "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
  //   type: "investor",
  //   investorDetails: {
  //     image: RayDalioFull,
  //     heading: " Who is Ray Dalio",
  //     list: [
  //       " Averaged 20% per year for over 50 years, making him one of the best investors in history",
  //       " Says the average person should just follow the 90/10 portfolio to reach wealth",
  //       "Pledged to donate 99% of his wealth",
  //     ],
  //     composition: {
  //       initial: 33.33,
  //       secondary: 33.33,
  //       tertiary: 33.33,
  //       others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
  //                             you invest in short-term bonds backed by the U.S.
  //                             government.`,
  //     },
  //   },
  // },

  {
    id: 4,
    image: JackBogle,
    name: "Jack Bogle",
    strategyName: "3 Fund Strategy",
    riskScore: 2.93,
    returnPercentage: 8.01,
    type: "investor",
    underlying: "_BOGLE",
    about:
      "This is a good balance between growing at a solid pace and not rising or dropping too quickly. It’s not the fastest, but it’s not the slowest either.",
    investorDetails: {
      image: JackBogleFull,
      heading: " Who is Jack Bogle",
      list: [
        "Founder of Vanguard, which now manages $7.2 trillion",
        "Popularized index funds and low-cost, passive investing",
        "Famous for trying to help the average investor grow their wealth safely",
      ],
      composition: [
        {
          name: "VTI",
          description:
            "Invests in every stock that is available in the U.S. stock market (3500+ companies). A passive way to invest in the U.S. economy.",
          percentage: 64,
        },
        {
          name: "BND",
          description:
            "Invests in intermediate-term bonds that are guaranteed by the U.S. government. It is safer and can provide reliable dividend income.",
          percentage: 20,
        },
        {
          name: "VXUS",
          description:
            "Invests in thousands of companies outside of the U.S., primarily in emerging countries, the Pacific, and Europe.",
          percentage: 16,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who prefer mild risk levels.",
        },
        {
          description:
            "This strategy is extremely diverse and effectively invests in over 11,000 different companies, both within the U.S. and internationally.",
        },
        {
          description:
            "This strategy does not sacrifice too much in the trade-off between potential returns and potential risk.",
        },
      ],
      drawbacks: [
        {
          description:
            "It can be hard to split smaller amounts of money across three different investments without using fractional shares.",
        },
        {
          description:
            "By being exposed to international companies, it opens this strategy up to more potential risks (i.e. currency, war, geopolitical).",
        },
      ],
    },
  },
  {
    id: 3,
    image: Benjamin,
    name: "Benjamin Graham",
    strategyName: "Defensive Investor",
    riskScore: 2.59,
    returnPercentage: 7.74,
    underlying: "_GRAHAM",
    about:
      "It does not grow fast, but it is very steady. During previous market crashes, this strategy only dropped by half as much as the rest of the stock market.",
    type: "investor",
    investorDetails: {
      image: PeterLynchFull,
      heading: "Who is Benjamin Graham",
      list: [
        "Father of value investing and mentor to Warren Buffett",
        "Wrote the most famous book on investing, The Intelligent Investor",
        "Averaged a 17% annual return for 30 years from 1926 - 1956 (30 years)",
      ],
      composition: [
        {
          name: "BND",
          description:
            "Invests in intermediate-term bonds that are guaranteed by the U.S. government. It is safer and can provide reliable dividend income.",
          percentage: 40,
        },
        {
          name: "VTI",
          description:
            "Invests in every stock that is available in the U.S. stock market (3500+ companies). A passive way to invest in the U.S. economy.",
          percentage: 60,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who want to grow their money steadily.",
        },
        {
          description:
            "This strategy pays consistent dividend income because it invests heavily in bonds.",
        },
        {
          description:
            "During the Great Recession, the rest of the stock market dropped over 48% while this strategy only dropped 28%.",
        },
        {
          description:
            "After the Great Recession, the stock market took 26 months to recover, while this strategy only took 14 months to recover.",
        },
      ],
      drawbacks: [
        {
          description:
            "Over time, it may grow much slower than other investment strategies because it takes less risk.",
        },
        {
          description:
            "During major market rises, this strategy may capture as much opportunity as others.",
        },
      ],
    },
  },
  {
    id: 5,
    image: ETF01,
    name: "Ticker: VOO",
    strategyName: "500 Largest Companies",
    riskScore: 3.6,
    returnPercentage: 13.33,
    underlying: "VOO",
    type: "etf",
    about:
      "Invests in a the 500 biggest U.S. companies. Most of it is invested in tech companies, but it includes almost every industry, from AI chips to candy bars.",
    investorDetails: {
      image: ETF01Detail,
      heading: "What is VOO?",
      list: [
        `Follows the top 500 big companies in the U.S.`,
        `One of the best-performing funds, offering steady long-term growth.`,
        `Averaged around 10% annual return since its start.`,
      ],
      holdings: [
        {
          name: "Apple Inc. (AAPL)",
          percentage: 6.97,
          link: false,
        },
        {
          name: "Microsoft Corp (MSFT",
          percentage: 6.54,
          link: false,
        },
        {
          name: "NVIDIA Corp (NVDA)",
          percentage: 6.2,
          link: false,
        },
        {
          name: "Amazon.com Inc (AMZN)",
          percentage: 3.45,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://investor.vanguard.com/investment-products/etfs/profile/voo",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Suitable for those comfortable with moderate risk for long-term growth",
        },
        {
          description:
            "Focuses on large, well-known companies, especially in tech, boosting potential returns",
        },
        {
          description:
            "Low-cost and easy to access, making it a popular choice for investors",
        },
      ],
      drawbacks: [
        {
          description:
            "Can be too risky for those who prefer stable or guaranteed returns",
        },
        {
          description:
            "If the market drops significantly, it may take time to recover due to its high stock exposure",
        },
      ],
    },
  },
  {
    id: 6,
    image: ETF02,
    name: "Ticker: QQQ",
    strategyName: "Big tech companies",
    riskScore: 3.85,
    returnPercentage: 13.33,
    type: "etf",
    about:
      "Invests in a the 500 biggest U.S. companies. Most of it is invested in tech companies, but it includes almost every industry, from AI chips to candy bars.",
    underlying: "QQQ",
    investorDetails: {
      image: ETF02Detail,
      heading: "What is QQQ?",
      list: [
        `Tracks the 100 biggest tech companies in the US`,
        `Focuses on fast-growing companies, mainly in tech`,
        `Known for its strong growth over time`,
      ],
      holdings: [
        {
          name: "Apple Inc. (AAPL)",
          percentage: 8.85,
          link: false,
        },
        {
          name: "Microsoft Corp (MSFT",
          percentage: 7.99,
          link: false,
        },
        {
          name: "NVIDIA Corp (NVDA)",
          percentage: 7.78,
          link: false,
        },
        {
          name: "Broadcom Inc (AVGO) ",
          percentage: 5.31,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://www.invesco.com/qqq-etf/en/about.html?utm_campaign=BL_QQQ_BAU_G_S_ININV_PROS_Brand&utm_source=Google&utm_medium=cpc&utm_content=qqq-cpc-paid-text&utm_term=12190225115_117285846619&utm_client=placeholder&utm_ver=placeholder&gad_source=1&gclid=Cj0KCQjw6oi4BhD1ARIsAL6pox1HI-X5o9l98c1p-9p-DPWS4HuXRmuPT8360ZKFH_4Nh0XBibb0A9gaAq4WEALw_wcB",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Suitable for those comfortable with moderate risk for long-term growth",
        },
        {
          description:
            "Focuses on large, well-known companies, especially in tech, boosting potential returns",
        },
        {
          description:
            "Low-cost and easy to access, making it a popular choice for investors",
        },
      ],
      drawbacks: [
        {
          description:
            "Can be too risky for those who prefer stable or guaranteed returns",
        },
        {
          description:
            "If the market drops significantly, it may take time to recover due to its high stock exposure",
        },
      ],
    },
  },
  {
    id: 7,
    image: ETF03,
    name: "Ticker: SCHD",
    strategyName: "Dividend investing",
    riskScore: 3.6,
    returnPercentage: 13.34,
    type: "etf",
    underlying: "SCHD",
    investorDetails: {
      image: ETF03Detail,
      heading: "What is SCHD?",
      list: [
        `  This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `   It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        `   It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 8,
    image: ETF04,
    name: "Ticker: GLD",
    strategyName: "Invest in Gold",
    riskScore: 2.95,
    returnPercentage: 10.26,
    type: "etf",
    underlying: "GLD",
    investorDetails: {
      image: ETF04Detail,
      heading: "What is GLD?",
      list: [
        `   This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `  It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        `  It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 9,
    image: ETF05,
    name: "Ticker: VUG",
    strategyName: "Big Growth Companies",
    riskScore: 3.9,
    returnPercentage: 8.31,
    type: "etf",
    underlying: "VUG",
    investorDetails: {
      image: ETF05Detail,
      heading: "What is VUG?",
      list: [
        ` Provides a convenient way to match the performance of many of
                the nation’s largest growth stocks.`,
        `     Follows a passively managed, full-replication approach with a
                0.04% expense ratio`,
      ],
    },
  },
  {
    id: 10,
    image: ETF06,
    name: "Ticker: IWM",
    strategyName: "Smaller Companies",
    riskScore: 4.3,
    returnPercentage: 7.47,
    type: "etf",
    underlying: "IWM",
    investorDetails: {
      image: ETF06Detail,
      heading: "What is IWM?",
      list: [
        `   This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `   It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 11,
    image: ETF07,
    name: "Ticker: VXUS",
    strategyName: "Global Economy",
    riskScore: 3.45,
    returnPercentage: 4.66,
    type: "etf",
    underlying: "VXUS",
    investorDetails: {
      image: ETF07Detail,
      heading: "What is VXUS?",
      list: [
        `    This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `     It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
  {
    id: 12,
    image: ETF08,
    name: "Ticker: DIA",
    strategyName: "U.S Economy",
    riskScore: 3.7,
    returnPercentage: 8.48,
    type: "etf",
    underlying: "DIA",
    investorDetails: {
      image: ETF08Detail,
      heading: "What is DIA?",
      list: [
        `  This gold ETF is one of the best ways to invest in gold, as it
                tracks the price of the gold bullion`,
        `  It was started in 2004 and is run by an extremely credible asset
                manager, SPDR`,
        ` It has a 0.40% expense ratio, which is average`,
      ],
    },
  },
];

const investorLabels = [
  {
    id: 1,
    label: "Your estimated investment",
    value: "$2,767,166",
    color: "#008037",
  },
  {
    id: 2,
    label: "Amount invested",
    value: "$460,000",
    color: "#50CFD7",
  },
  {
    id: 3,
    label: "Expected net gain",
    value: "$2,307,166",
    color: "#FFF",
  },
];

const brokers = [
  {
    id: 1,
    image: Alpaca,
    name: "Brokerage # ---5914",
    balance: "24,159.88",
    text: "You have $5,157.92 in cash to invest in this account.",
  },
];

export { stocksList, investorLabels, brokers };
